/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable spaced-comment */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { HeaderBase } from '@react-ib-mf/header-menu-ui';
import { isCheckOTP, UnActivatedToken, pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { useIsFetching, useQuery } from 'react-query';
import imgEmptyState from '../assets/cable.png';
import ChipsSaldosAcuerdos from '../components/ChipsSaldosAcuerdos';
import ScreenError from '../components/ScreenError/ScreenError';
import { AppContext } from '../provider/AppContextProvider';
import saldosText from '../lang/es.json';
import AccordionSaldos from '../components/AccordionSaldos';
import { IAccordionSaldos } from '../../interfaces/IResponseData';
import SkeletonChips from '../components/SkeletonChips';

function Saldos() {
  const [isSearch, setIsSearch] = useState(false);
  const [dataTableInput, setDataTableInput] = useState<IAccordionSaldos>({
    codigoBanco: '',
    tipoCuenta: '',
    moneda: '',
  });

  const isFetchingDataTable = useIsFetching(['GetLastBalances']);

  const { chipSaldosFetched, getChipData, errorDataSaldos } = useContext(AppContext) as any;
  getChipData();

  useEffect(() => {
    isFetchingDataTable === 0 && setIsSearch(false);
  }, [isFetchingDataTable]);

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Consolidado de saldos',
    });
  }, []);

  return (
    <>
      <HeaderBase
        title={saldosText.title}
        tooltipText=''
        tooltipTextComponent={<p style={{ margin: '5px' }}>{saldosText.tooltipTextMain}</p>}
        breadcrumb={saldosText.breadcrumbOptions}
      />

      {isCheckOTP() ? (
        <>
          {errorDataSaldos && (
            <ScreenError
              title={saldosText.screenErrorText.title}
              description={saldosText.screenErrorText.description}
              image={imgEmptyState}
            />
          )}
          {chipSaldosFetched && (
            <>
              <ChipsSaldosAcuerdos
                onSearch={() => setIsSearch(true)}
                onChangeChips={e => {
                  setDataTableInput(e);
                }}
                isFetching={isFetchingDataTable === 1}
              />
              <AccordionSaldos searchValues={dataTableInput} isSearch={isSearch} />
            </>
          )}
          {!chipSaldosFetched && !errorDataSaldos && <SkeletonChips />}
        </>
      ) : (
        <UnActivatedToken />
      )}
    </>
  );
}

export default Saldos;
