/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { CustomSelect, pushAnalyticsEvent, useToastOptions } from '@react-ib-mf/style-guide-ui';
import { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiSliders } from 'react-icons/fi';
import { useQuery } from 'react-query';

import { Banco, IOption } from '../../../interfaces/IPropsCustomSelect';
import { getBanksForConsolidated } from '../../../services/api';
import { BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS, BORDER_COLOR_BOX_CHIP_EXTRACTOS } from '../../designTokens/colors';
import customText from '../../lang/es.json';
import {
  clearFilterBank,
  createObjectFilter,
  formatTypeAccountLabel,
  getCurrencyForSelectedBanks,
  getDefaultBank,
  getTypeAccountsForSelectedBanks,
} from '../../utils/chipSaldos';
import MultiSelect from '../MultiSelect';
import { ScrollButon, ScrollContainer, ScrollWrapper } from './styled';

export default function ChipsSaldosAcuerdos({ onSearch, isFetching, onChangeChips }: any) {
  const [objectSelectedBank, setObjectSelectedBank] = useState<Banco | undefined>();

  const [selectedBank, setSelectedBank] = useState<IOption | undefined>();
  const [selectedTypeAccount, setSelectedTypeAccounts] = useState<IOption | undefined>();
  const [selectedCurrency, setSelectedCurrency] = useState<IOption | undefined>();

  type typesDataOptions = IOption[] | [] | any;

  const [bankData, setBankData] = useState<typesDataOptions>([]);
  const [typeAccountsData, setTypeAccountsData] = useState<typesDataOptions>([]);
  const [currenciesData, setCurrenciesData] = useState<typesDataOptions>([]);

  const [isClearDisabled, setIsClearDisabled] = useState(true);
  const [isFetchingDisabled, setIsFetchingDisabled] = useState(true);

  const selectedInitState = {
    isSelectedBank: false,
    isSelectedTypeAccount: false,
    isSelectedCurrency: false,
  };

  const [selectedChip, setSelectedChip] = useState(selectedInitState);
  const [selectedChipData, setSelectedChipData] = useState({});
  const [selectedChipDefault, setSelectedChipDefault] = useState({});

  const { data: dataForFilters, isFetching: dataForFiltersFetching } = useQuery(['GetBanksForConsolidated'], () =>
    getBanksForConsolidated().then(res => res.data),
  );

  const [, setToastOptions] = useToastOptions();

  const defaultBank = getDefaultBank(dataForFilters);

  const clearFilters = () => {
    clearFilterBank(bankData);
    setSelectedBank(bankData.filter(bank => bank.default)[0]);
    setSelectedTypeAccounts(typeAccountsData.filter(typeAccount => typeAccount.default)[0]);
    setSelectedCurrency(currenciesData.filter(currency => currency.default)[0]);
    setSelectedChip(selectedInitState);
    setIsClearDisabled(true);
    setToastOptions({ description: '¡Listo! Borraste los filtros con éxito.', status: 'success' });
    setDisableRight(false);
    pushAnalitycsRemove('CTA Borrar filtros');
  };

  // 1
  // Se crea el objeto para el filtro de bancos y se setea el banco por defecto
  useEffect(() => {
    if (!dataForFiltersFetching && dataForFilters !== undefined) {
      const bankList = dataForFilters?.data.bancos;
      const filterBanks = bankList !== undefined ? createObjectFilter(bankList, 'idBanco', 'nombreBanco') : [];
      if (filterBanks.length > 0) filterBanks[0].default = true;
      const selectedOption = filterBanks.filter(bank => bank.default)[0];
      setSelectedBank(selectedOption);
      setBankData(filterBanks);
    } else {
      setBankData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForFilters, dataForFiltersFetching]);

  useEffect(() => {
    if (selectedBank !== undefined) {
      if (!selectedBank.value.includes(',')) {
        setBankData(prevBankData =>
          prevBankData.map(option => ({
            ...option,
            default: option.value === selectedBank.value,
          })),
        );
      } else {
        const bankValues = selectedBank.value.split(',');
        setBankData(prevBankData =>
          prevBankData.map(bankItem => ({
            ...bankItem,
            default: bankValues.includes(bankItem.value),
          })),
        );
      }
    }
  }, [setBankData, selectedBank]);

  // Creamos un objeto del banco seleccionado y lo guardamos en un estado
  useEffect(() => {
    if (selectedBank !== undefined) {
      if (!selectedBank.value.includes(',')) {
        const currentBank = dataForFilters?.data.bancos.filter(banco => banco.idBanco === selectedBank.value)[0];
        setObjectSelectedBank([currentBank] as any);
      } else {
        const bankValue = selectedBank.value.split(',');

        const currentBank = dataForFilters?.data.bancos.filter(banco => bankValue.includes(banco.idBanco));
        setObjectSelectedBank(currentBank as any);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBank]);

  // Se crea el objeto para el filtro tipo de cuentas y se setea el tipo de cuenta corriente por defecto
  useEffect(() => {
    if (objectSelectedBank !== undefined) {
      formatTypeAccountLabel(objectSelectedBank);
      setTypeAccountsData(getTypeAccountsForSelectedBanks(selectedBank, objectSelectedBank));
    }

    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectSelectedBank]);

  useEffect(() => {
    if (typeAccountsData !== undefined) {
      const defaultOption = typeAccountsData?.filter(typeAccount => !typeAccount.isDisabled);
      const selectedOption = [...typeAccountsData];

      if (defaultOption.length > 1) {
        selectedOption[2].default = true;
      } else if (defaultOption.length === 1) {
        if (defaultOption[0].value === 'CC') {
          selectedOption[0].default = true;
        } else {
          selectedOption[1].default = true;
        }
      }

      setSelectedTypeAccounts(selectedOption.filter(typeAccount => typeAccount.default)[0]);
    }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeAccountsData]);

  // Se crea el objeto para el filtro tipo de cuentas y se setea el tipo de cuenta corriente por defecto
  useEffect(() => {
    if (objectSelectedBank !== undefined) {
      setCurrenciesData(getCurrencyForSelectedBanks(selectedTypeAccount, objectSelectedBank));
    }

    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectSelectedBank, selectedTypeAccount]);
  // Se crea el objeto para el filtro moneda y se setea la moneda ARS por defecto

  useEffect(() => {
    if (currenciesData !== undefined) {
      const defaultOption = currenciesData?.filter(currency => !currency.isDisabled);
      const selectedOption = [...currenciesData];

      if (defaultOption.length > 1) {
        selectedOption[2].default = true;
      } else if (defaultOption.length === 1) {
        if (defaultOption[0].value === 'ARS') {
          selectedOption[0].default = true;
        } else {
          selectedOption[1].default = true;
        }
      }

      setSelectedCurrency(selectedOption.filter(currency => currency.default)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenciesData]);

  useEffect(() => {
    if (Object.values(selectedChip).includes(true)) {
      setIsClearDisabled(false);
    } else {
      setIsClearDisabled(true);
    }
  }, [selectedChip]);

  useEffect(() => {
    const inputData = {
      codigoBanco: selectedBank !== undefined ? selectedBank.value : '',
      tipoCuenta: selectedTypeAccount !== undefined ? selectedTypeAccount.value : '',
      moneda: selectedCurrency !== undefined ? selectedCurrency.value : '',
    };

    setSelectedChipData(inputData);

    const defaultState = JSON.stringify(selectedChipDefault);
    const newState = JSON.stringify(inputData);
    if (defaultState !== newState && Object.values(selectedChipDefault).length > 0) {
      setIsFetchingDisabled(false);
    } else {
      setIsFetchingDisabled(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBank, selectedTypeAccount, selectedCurrency]);

  onChangeChips(selectedChipData);

  useEffect(() => {
    if (!isFetching) {
      setSelectedChipDefault(selectedChipData);
      setIsFetchingDisabled(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  function pushAnalitycsFilter(applied: string) {
    pushAnalyticsEvent({
      event: 'filter_listing',
      filter_type: applied,
    });
  }

  function pushAnalitycsRemove(remove: string) {
    pushAnalyticsEvent({
      event: 'remove_filters',
      selection: remove,
    });
  }

  const [scrollLeft, setScrollLeft] = useState(0);
  const [disableRight, setDisableRight] = useState(false);

  const sliderLeft = () => {
    const slider = document.getElementById('slider');
    const firstMove = 86;
    if (slider) {
      const anchoTotal = slider.scrollWidth;
      const anchoVisible = slider.clientWidth;
      let scrollActual = slider.scrollLeft;
      scrollActual -= firstMove;
      const dif = anchoTotal - anchoVisible;
      if (scrollActual <= dif) {
        setDisableRight(false);
      }
      if (scrollActual === dif) {
        slider.scrollLeft -= 100;
        setScrollLeft(slider.scrollLeft);
      } else {
        slider.scrollLeft -= firstMove;
        setScrollLeft(slider.scrollLeft);
      }
    }
  };

  const sliderRight = () => {
    const slider = document.getElementById('slider');
    const firstMove = 86;
    if (slider) {
      const anchoVisible = slider?.clientWidth;
      let scrollActual = slider?.scrollLeft;
      scrollActual += firstMove;
      const anchoTotal = slider?.scrollWidth;
      const dif = anchoTotal - anchoVisible;
      if (scrollActual >= dif) {
        setDisableRight(true);
      }
      slider.scrollLeft += 100;
      setScrollLeft(slider.scrollLeft);
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      w='auto'
      marginTop='24px'
      backgroundColor='transparent'
      alignContent='center'
      alignItems='center'
      borderRadius='8px'
      borderStyle='solid'
      borderWidth='1px'
      borderColor={BORDER_COLOR_BOX_CHIP_EXTRACTOS}
      height='64px'
    >
      <Box display='flex' flexDirection='row' gap='8px' paddingLeft='10px' justifyContent='start' alignItems='center'>
        <Button size='md' variant='secondary-text' isDisabled leftIcon={<FiSliders />} style={{ cursor: 'default' }}>
          {customText.chipsSaldosAcuerdos.textButton}
        </Button>
        <div style={{ display: 'flex' }}>
          <ScrollButon
            data-testid={customText.chipsSaldosAcuerdos.scrollLeft}
            disabled={scrollLeft === 0}
            style={{
              paddingRight: '2px',
              border: scrollLeft === 0 ? `1px solid ${BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS}` : '',
            }}
            position='left'
            onClick={() => sliderLeft()}
          >
            <FiChevronLeft
              style={{ paddingRight: '5px' }}
              size='24px'
              color={scrollLeft === 0 ? BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS : ''}
            />
          </ScrollButon>
          <ScrollWrapper>
            <ScrollContainer id='slider' style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
              <CustomSelect
                name='Consolidado de saldos'
                options={{}}
                onSelectOption={() => null}
                value={{
                  value: 'Consolidado de saldos',
                  label: 'Consolidado de saldos',
                  default: true,
                  description: 'Consolidado de saldos',
                  customLabel: false,
                }}
                label='Consolidado de saldos'
                onClearFilter={() => null}
                disabled
              />
              <MultiSelect
                name={customText.customSelect.nameBankId}
                data-testid={customText.customSelect.nameBankId}
                options={bankData}
                onSelectOption={e => {
                  setSelectedBank(e);
                  setSelectedChip({ ...selectedChip, isSelectedBank: e.value === bankData[0]?.value ? false : !e.default });
                  pushAnalitycsFilter('Aplicado-Banco');
                }}
                value={selectedBank}
                label={customText.customSelect.labelBank}
                onClearFilter={() => {
                  setSelectedBank(bankData.filter(bank => bank.default)[0]);
                  setSelectedChip({ ...selectedChip, isSelectedBank: false });
                  pushAnalitycsRemove('Banco');
                }}
                onCheckedOptions={selectedBank}
                defaulValue={defaultBank}
                chipSelectedDefault={selectedChipData}
              />

              <CustomSelect
                name={customText.customSelect.nameAccountTypeId}
                data-testid={customText.customSelect.nameAccountTypeId}
                options={typeAccountsData}
                onSelectOption={e => {
                  setSelectedTypeAccounts(e);
                  setSelectedChip({ ...selectedChip, isSelectedTypeAccount: !e.default });
                  pushAnalitycsFilter('Aplicado-TipoCuenta');
                }}
                value={
                  selectedTypeAccount && selectedTypeAccount.value === 'CA+CC'
                    ? { value: 'CA+CC', label: 'CC y CA', isDisabled: false, default: true }
                    : selectedTypeAccount
                }
                label={customText.customSelect.labelAccountType}
                onClearFilter={() => {
                  setSelectedTypeAccounts(typeAccountsData.filter(typeAccount => typeAccount.default)[0]);
                  setSelectedChip({ ...selectedChip, isSelectedTypeAccount: false });
                  pushAnalitycsRemove('TipoCuenta');
                }}
              />
              <CustomSelect
                name={customText.customSelect.nameCurrencyId}
                data-testid={customText.customSelect.nameCurrencyId}
                options={currenciesData}
                onSelectOption={e => {
                  setSelectedCurrency(e);
                  setSelectedChip({ ...selectedChip, isSelectedCurrency: !e.default });
                  pushAnalitycsFilter('Aplicado-Moneda');
                }}
                value={selectedCurrency}
                label={customText.customSelect.labelCurrency}
                onClearFilter={() => {
                  setSelectedCurrency(currenciesData.filter(currency => currency.default)[0]);
                  setSelectedChip({ ...selectedChip, isSelectedCurrency: false });
                  pushAnalitycsRemove('Moneda');
                }}
              />
            </ScrollContainer>
          </ScrollWrapper>
          <ScrollButon
            disabled={disableRight}
            data-testid={customText.chipsSaldosAcuerdos.scrollRight}
            style={{ paddingLeft: '2px', border: disableRight ? `1px solid ${BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS}` : '' }}
            position='rigth'
            onClick={() => sliderRight()}
          >
            <FiChevronRight
              style={{ paddingLeft: '5px' }}
              size='24px'
              color={disableRight ? BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS : ''}
            />
          </ScrollButon>
        </div>
      </Box>
      <Box display='flex' flexDirection='row' gap='16px' alignItems='center' paddingRight='10px'>
        <Button onClick={() => onSearch()} isDisabled={isFetchingDisabled} size='md'>
          {customText.chipsSaldosAcuerdos.customButton}
        </Button>
        <Button data-testid='btn-clearFilters' size='md' variant='primary-text' onClick={() => clearFilters()} isDisabled={isClearDisabled}>
          {customText.chipsSaldosAcuerdos.textButtonDelete}
        </Button>
      </Box>
    </Box>
  );
}
