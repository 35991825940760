import { Outlet } from 'react-router-dom';

import AppContainerPanel from './styled';

function AppContainer() {
  return (
    <AppContainerPanel>
      <Outlet />
    </AppContainerPanel>
  );
}

export default AppContainer;
