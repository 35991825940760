import { Skeleton, SkeletonCircle } from '@chakra-ui/skeleton';

function SkeletonChips() {
  return (
    <div
      id='skeleton-chips-container'
      data-testid='skeleton-chips'
      style={{
        display: 'flex',
        marginTop: '24px',
        borderRadius: '8px',
        padding: '12px',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex' }}>
        <SkeletonCircle size='10' marginRight='2px' />
        <Skeleton size='40px' width='50px' borderRadius='8px' marginRight='20px' />
        <Skeleton height='40px' width='210px' borderRadius='8px' marginRight='5px' />
        <Skeleton height='40px' width='110px' borderRadius='8px' marginRight='5px' />
        <Skeleton height='40px' width='110px' borderRadius='8px' marginRight='5px' />
        <Skeleton height='40px' width='110px' borderRadius='8px' marginRight='5px' />
      </div>
      <div style={{ display: 'flex' }}>
        <Skeleton height='40px' width='80px' borderRadius='8px' marginRight='15px' />
        <Skeleton height='40px' width='100px' borderRadius='8px' />
      </div>
    </div>
  );
}

export default SkeletonChips;
