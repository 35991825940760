/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
/* eslint-disable spaced-comment */
import { themeChakra } from '@wow/themes';

//INTRO: Centralizamos los estilos de cada componente en un unico archivo, para poder cambiarlos rapido
//sin tener que navegar por el componente. Usamos los colores del theme de wow, libreria de DS.

//SINTAXIS: 1° props css, 2° componente del componente, 3° nombre del componente como tal.
//EJEMPLO:    BACKGROUND_SCROLL_BTN_CHIP_EXTRACTOS

// Que hacer?
//Pararse en la seccion del componente o crear una nueva EJ: //CHIP EXTRACTOS COMPONENT, luego crear una constante con la
//sintaxis definida arriba para asignarle un valor de color del theme de Chackra -> http://devdesignsystem-ui.apps.dev.ib.ar/?path=/docs/design-tokens-colores--page
//Este export const nos va a permitir importar la constante en el lugar que queramos, sea jsx,tsx,ts,css.

//COMPONENTES:

//CHIP EXTRACTOS COMPONENT
export const BACKGROUND_SCROLL_BTN_CHIP_EXTRACTOS = themeChakra.colors.neutral[90];
export const COLOR_SCROLL_BTN_CHIP_EXTRACTOS = themeChakra.colors.neutral[110];
export const BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS = themeChakra.colors.grey[40];
export const BORDER_COLOR_BOX_CHIP_EXTRACTOS = themeChakra.colors.secondary[60];

//CUSTOM BUTTON COMPONENT
export const BACKGROUND_BTN_CUSTOM_BUTTON = themeChakra.colors.primary[100];
export const BACKGROUND_BTN_DISABLED_CUSTOM_BUTTON = themeChakra.colors.grey[40];
export const COLOR_BTN_DISABLED_CUSTOM_BUTTON = themeChakra.colors.grey[60];

//SCREEN ERROR COMPONENT
export const COLOR_TITLE_SCREEN_ERROR = themeChakra.colors.grey[120];

//TABLA EXTRACTOS COMPONENT / Style.tsx
export const BACKGROUND_THEAD_TH_TABLA_EXTRACTOS = themeChakra.colors.grey[40];
export const COLOR_SPAN_TABLA_EXTRACTOS = themeChakra.colors.grey[120];
export const COLOR_TBODY_TD_TABLA_EXTRACTOS = themeChakra.colors.grey[120];
export const COLOR_TBODY_TD_POSITIVE_TABLA_EXTRACTOS = themeChakra.colors.grey[120];
export const COLOR_TBODY_TD_NEGATIVE_TABLA_EXTRACTOS = themeChakra.colors.danger[100];
export const BACKGROUND_TBODY_TD_BRANCHCODE_TABLA_EXTRACTOS = themeChakra.colors.info[50];
export const COLOR_TBODY_TD_BRANCHCODE_TABLA_EXTRACTOS = themeChakra.colors.info[100];
export const COLOR_TOOLTIP_TABLA_EXTRACTOS = themeChakra.colors.grey[40];
export const BACKGROUND_TOOLTIP_TABLA_EXTRACTOS = themeChakra.colors.grey[120];

//REPORTS COMPONENT
export const BACKGROUND_BTN_REPORTS_OPEN = themeChakra.colors.primary[50];
export const BACKGROUND_BTN_REPORTS_NOT_OPEN = themeChakra.colors.white;
export const BORDER_COLOR_BTN_REPORTS = themeChakra.colors.primary[100];
export const TITLE_COLOR_BTN_REPORTS = themeChakra.colors.primary[100];
export const BACKGROUND_DROPDOW_REPORTS = themeChakra.colors.white;
export const BACKGROUND_DROPDOW_OPTION_REPORTS = themeChakra.colors.white;
export const COLOR_DROPDOW_OPTION_REPORTS = themeChakra.colors.grey[120];
export const HOVER_BACKGROUND_DROPDOW_OPTION_REPORTS = themeChakra.colors.primary[50];
export const COLOR_CHEVRON_REPORTS = themeChakra.colors.primary[100];

//TABLE COMPONENT / COLUMN CHECKBOX COMPONENT
export const BACKGROUND_COLUMN_CHECKBOX = themeChakra.colors.primary[100];
export const BORDER_COLOR_COLUMN_CHECKBOX = themeChakra.colors.neutral[90];
export const COLOR_TITLE_COLUMN_CHECKBOX = themeChakra.colors.primary[100];
export const COLOR_TITLE_NOT_COLUMN_CHECKBOX = themeChakra.colors.grey[120];
export const BORDER_COLOR_DEFAULT_COLUMN_CHECKBOX = themeChakra.colors.grey[60];
export const BACKGROUND_DEFAULT_COLUMN_CHECKBOX = themeChakra.colors.grey[40];
export const BACKGROUND_NOT_DEFAULT_CHEKEND_COLUMN_CHECKBOX = themeChakra.colors.primary[100];
export const BACKGROUND_NOT_DEFAULT_UNCHEKEND_COLUMN_CHECKBOX = themeChakra.colors.white;
export const COLOR_FICHECK_DEFAULT_COLUMN_CHECKBOX = themeChakra.colors.grey[60];
export const COLOR_FICHECK_NOT_DEFAULT_COLUMN_CHECKBOX = themeChakra.colors.white;
export const COLOR_TITLE_CHECKBOX_DEFAULT_COLUMN_CHECKBOX = themeChakra.colors.grey[60];

//TABLE COMPONENT / CONFIG COLUMNS COMPONENT
export const COLOR_FISETTINGS_CONFIG_COLUMS = themeChakra.colors.primary[100];

//DETAIL SELECTED ACCOUNT COMPONENT
export const BORDER_BOTTOM_CONTAINER_DETAIL_SELECTED_ACCOUNT = themeChakra.colors.grey[60];
export const COLOR_NOMBRE_BANCO_DETAIL_SELECTED_ACCOUNT = themeChakra.colors.grey[120];
export const COLOR_DETALLE_GRUPO_DETAIL_SELECTED_ACCOUNT = themeChakra.colors.grey[120];
export const COLOR_DETALLE_GRUPO_SPAN_DETAIL_SELECTED_ACCOUNT = themeChakra.colors.neutral[90];
export const COLOR_DENOMINACION_DETAIL_SELECTED_ACCOUNT = themeChakra.colors.neutral[90];
export const COLOR_SALDO_INICIAL_DETAIL_SELECTED_ACCOUNT = themeChakra.colors.neutral[90];
export const COLOR_SALDO_FINAL_DETAIL_SELECTED_ACCOUNT = themeChakra.colors.neutral[90];
export const BORDER_LOGO_DETAIL_SELECTED_ACCOUNT = themeChakra.colors.neutral[90];

//EMPTY STATES COMPONENT
export const COLOR_TITLE_EMPTY_STATES = themeChakra.colors.grey[120];
export const COLOR_DESCRIPTION_EMPTY_STATES = themeChakra.colors.grey[120];

//UNACTIVED TOKEN COMPONENT
export const COLOR_UNACTIVED_TOKEN = themeChakra.colors.grey[120];
