// eslint-disable-next-line import/no-unresolved
import { ErrorPermissionContent, pushAnalyticsFromError, WithPermissions } from '@react-ib-mf/style-guide-ui';
import React from 'react';
import { useQuery } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AppContainer from './layouts/AppContainer';
import AppContextProvider, { getPerfilMenu } from './provider/AppContextProvider';
import { permissionsSaldos } from './utils/permissions';
import Saldos from './views/Saldos';

function App() {
  const { data } = useQuery('perfil', () => getPerfilMenu().then(res => res.data), { retry: 2, onError: pushAnalyticsFromError });

  const optionsvalue = data?.data?.options[2].label;
  const hasNewPlan = data?.data?.hasNewPlan;

  return (
    <AppContextProvider>
      <Router basename='/consultas'>
        <Routes>
          <Route path='/' element={<AppContainer />}>
            {optionsvalue === 'Plan Gratuito' && hasNewPlan === false ? (
              <Route path='/saldos' element={<ErrorPermissionContent />} />
            ) : (
              <Route
                path='/saldos'
                element={
                  <WithPermissions permission={permissionsSaldos} showErrorPage>
                    <Saldos />
                  </WithPermissions>
                }
              />
            )}
          </Route>
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;
