import React from 'react';
import { AccordionSectionContainer } from './styled';
import { Box } from '@chakra-ui/layout';
import customText from '../../../../lang/es.json';
import AccordionBankItem from '../AccordionBankItem';
import { sumLastConsolidatedBalance } from '../../../../utils/accordion';

function AccordionSection({ currency, banks, accounts, data }) {
  const renderAccordionItems = currencySelected => {
    return (
      banks &&
      banks?.map((bank, index) => {
        return (
          <AccordionBankItem
            key={index}
            bank={bank}
            accounts={accounts}
            data={data}
            currency={currencySelected}
            id={`Accordion-item-${bank}-saldos`}
          />
        );
      })
    );
  };

  return (
    <AccordionSectionContainer>
      {`${customText.accordion.section.title} ${currency === 'ARS' ? 'pesos' : 'dólares'}`}
      <Box className='section-header-container'>
        <Box className='section-header-title'>{`${customText.accordion.section.subtitle}`}</Box>
        <Box className='section-header-total'>
          {sumLastConsolidatedBalance(data, currency).value}
          <Box className='section-header-total-cents'>{sumLastConsolidatedBalance(data, currency).cent}</Box>
        </Box>
      </Box>
      {renderAccordionItems(currency)}
    </AccordionSectionContainer>
  );
}

export default AccordionSection;
