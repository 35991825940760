import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Box } from '@chakra-ui/layout';
import React, { useContext } from 'react';

import customText from '../../../../lang/es.json';
import { AppContext } from '../../../../provider/AppContextProvider';
import { filterAccounts, formatLastUpdatedDate, getLastConsolidatedBalanceByBank } from '../../../../utils/accordion';
import AccordionTable from '../AccordionTable';
import { AccordionBankContainer } from './styled';

function AccordionBankItem({ bank, accounts, data, currency }) {
  const { bankNames } = useContext(AppContext);

  const bankTotal = getLastConsolidatedBalanceByBank(data, currency, bank);
  const bankDates = formatLastUpdatedDate(data, currency, bank);
  const bankaccounts = filterAccounts(data, currency, accounts, bank);

  return (
    bankaccounts?.length !== 0 && (
      <AccordionBankContainer>
        <Accordion allowMultiple>
          <AccordionItem borderTopWidth='0px'>
            {({ isExpanded }) => (
              <div className={`accordion-bank-item ${isExpanded ? 'expanded' : ''}`}>
                <Box className={`accordion-bank-item-header ${isExpanded ? 'expanded' : ''}`}>
                  <Box className='accordion-bank-item-header-brand'>
                    <Box className='accordion-bank-item-header-brand-icon'>
                      <img src={`${process.env.URL_BANK_LOGOS}logo${bank}.png`} />
                    </Box>
                    <Box className='accordion-bank-item-header-brand2'>
                      <Box className='accordion-bank-item-header-brand2-name'>
                        {bankNames?.filter(bankItems => bankItems.idBanco === bank)?.[0]?.nombreBanco}
                      </Box>
                      <Box className='accordion-bank-item-header-brand2-date'>
                        {`Datos actualizados al ${bankDates?.date} - ${bankDates?.time} horas ${
                          bankDates.online && bankDates.online !== undefined ? `- ${customText.accordion.section.onlineMessage}` : ''
                        }`}
                      </Box>
                    </Box>
                  </Box>
                  <Box className='accordion-bank-item-header-total'>
                    <Box className='accordion-bank-item-header-subtotal'>
                      <Box className='accordion-bank-item-header-subtotal-value'>
                        {bankTotal?.value}
                        <Box className='section-header-total-cents'>{bankTotal?.cent}</Box>
                      </Box>
                      <Box className='accordion-bank-item-header-subtotal-label'>Subtotal</Box>
                    </Box>
                    <AccordionButton _hover={{ bg: 'transparent' }}>
                      <AccordionIcon color='#005BBF' fontSize='30px' />
                    </AccordionButton>
                  </Box>
                </Box>
                <AccordionPanel className='accordion-bank-item-panel' pb={4} borderBottomStyle='none'>
                  <AccordionTable accountData={bankaccounts} currencySelected={currency} />
                </AccordionPanel>
              </div>
            )}
          </AccordionItem>
        </Accordion>
      </AccordionBankContainer>
    )
  );
}

export default AccordionBankItem;
