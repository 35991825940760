// eslint-disable-next-line import/no-unresolved
import { useToastOptions } from '@react-ib-mf/style-guide-ui';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { getLastBalances } from '../../../services/api';
import messages from '../../lang/es.json';
import { formatParams } from '../../utils/accordion';
import ReportsComponents from '../ReportsComponent';
import SkeletonSaldos from '../SkeletonSaldos';
import AccordionSection from './components/AccordionSection';
import { AccordionContainer } from './styled';

function AccordionSaldos({ searchValues, isSearch }) {
  const [hasDataSelected, setHasDataSelected] = useState(false);
  const [bankSelected, setBankSelected] = useState([]);
  const [accountTypeSelected, setAccountTypeSelected] = useState([]);
  const [currencySelected, setCurrencySelected] = useState([]);

  const [, setToastOptions] = useToastOptions();

  const queryClient = useQueryClient();

  const params = {
    bankCode: formatParams(searchValues.codigoBanco, ',', '&bankCode='),
    currency: formatParams(searchValues.moneda, ' y ', '&currency='),
    accountType: formatParams(searchValues.tipoCuenta, '+', '&accountType='),
  };

  const {
    data: dataAccordion,
    isFetching: isDataAccordionFetching,
    isError: isErrorDataAccordion,
  } = useQuery(
    ['GetLastBalances'],
    () =>
      getLastBalances(params).then(res => {
        dataFilterSelected();
        return res.data;
      }),
    {
      enabled: !!params.bankCode && !!params.currency && !!params.accountType,
      onError: error => {
        // Manejar errores generales aquí
        console.error('Error al obtener los últimos saldos:', error);
      },
    },
  );

  useEffect(() => {
    if (isSearch) {
      queryClient.fetchQuery(['GetLastBalances']);
    }
  }, [isSearch, queryClient]);

  useEffect(() => {
    if (isErrorDataAccordion) {
      setToastOptions({ description: messages.toast.errorDescription, status: 'error' });
    }
  }, [isErrorDataAccordion]);

  const dataFilterSelected = () => {
    const bank = searchValues?.codigoBanco?.split(',');
    const accountType = searchValues?.tipoCuenta?.split('+');
    const currency = searchValues?.moneda?.replace(/\s/g, '').split('y');

    setBankSelected(bank);
    setAccountTypeSelected(accountType);
    setCurrencySelected(currency);
    setHasDataSelected(bank && accountType && currency);
  };

  const renderAccordion = () => {
    return (
      currencySelected &&
      currencySelected?.map((currency, index) => {
        return (
          <AccordionSection
            key={index}
            currency={currency}
            banks={bankSelected}
            accounts={accountTypeSelected}
            data={dataAccordion?.balances}
            id={`Accordion-section-${currency}-saldos`}
          />
        );
      })
    );
  };

  return (
    <>
      {!isErrorDataAccordion && (
        <AccordionContainer id='Accordion-container-saldos'>
          {isDataAccordionFetching && <SkeletonSaldos />}
          {hasDataSelected && !isDataAccordionFetching && (
            <>
              <ReportsComponents queryParams={params} />
              {renderAccordion()}
            </>
          )}
        </AccordionContainer>
      )}
    </>
  );
}

export default AccordionSaldos;
