/* eslint-disable dot-notation */
/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import { IDataFiltros } from '../interfaces/IPropsCustomSelect';
import { IResponseData } from '../interfaces/IResponseData';
import { IServiceGetBalance } from '../interfaces/IServiceGetBalance';
import mockBancos2 from '../test/__mocks__/responseDataForFilter.json';
import mockBalances from '../test/__mocks__/mockBalances.json';
import getTokenForSessionStorage from '../src/utils/sesionStorage';
/*
// No se debe setear el axios.defaults.URLBase ya que pisa la url de mf-home-interna
*/
const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'x-jwt-assertion': getTokenForSessionStorage(),
    'Content-Type': 'application/json',
  },
});

const getBancosMock = async () => {
  return new Promise<IResponseData<IDataFiltros>>(resolve => {
    setTimeout(() => {
      return resolve(JSON.parse(JSON.stringify(mockBancos2)));
    }, 0);
  });
};

const getBalancesMock = () => {
  return new Promise<IResponseData<IServiceGetBalance>>(resolve => {
    setTimeout(() => {
      return resolve(JSON.parse(JSON.stringify(mockBalances)));
    }, 3000);
  });
};

const getBancos = () => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY_CONSULTIVO;
  return axiosInstance.get<IResponseData<IDataFiltros>>(`${process.env.CONSULTIVO_API_BASE_URL}extractos/obtenerDatosParaFiltro`);
};

const getBanksForConsolidated = () => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY_BALANCES;
  return axiosInstance.get<any>(`${process.env.BALANCES_BFF_URL_BASE}/BanksForConsolidated`);
};

const getLastBalances = params => {
  const { bankCode, currency, accountType } = params;
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY_BALANCES;
  return axiosInstance.get<any>(`${process.env.BALANCES_BFF_URL_BASE}/lastBalances?${bankCode}${currency}${accountType}`);
};

const getDownloadASCIFile = params => {
  const { bankCode, currency, accountType } = params;
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY_BALANCES;
  return axiosInstance.get<any>(`${process.env.BALANCES_BFF_URL_BASE}/reports/ascii?${bankCode}${currency}${accountType}`);
};

const getDownloadCSVFile = params => {
  const { bankCode, currency, accountType } = params;
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY_BALANCES;
  return axiosInstance.get<any>(`${process.env.BALANCES_BFF_URL_BASE}/reports/csv?${bankCode}${currency}${accountType}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'text/csv; charset=utf-8',
    },
  });
};

const getDownloadPDFFile = params => {
  const { bankCode, currency, accountType } = params;
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY_BALANCES;
  return axiosInstance.get<any>(`${process.env.BALANCES_BFF_URL_BASE}/reports/pdf?${bankCode}${currency}${accountType}`, {
    responseType: 'arraybuffer',
  });
};

export {
  getBalancesMock,
  getBancos,
  getBancosMock,
  getBanksForConsolidated,
  getDownloadASCIFile,
  getDownloadCSVFile,
  getDownloadPDFFile,
  getLastBalances,
};
