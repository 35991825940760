/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import customText from '../../lang/es.json';
import { Container } from './Styled';

function OptionCheckbox({ options, onChange, checkedOptions, highlightText }: any) {
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState<Record<string, boolean>>(
    options.reduce((acc: any, checkbox: any) => {
      acc[checkbox.label] = checkbox?.default ?? false;
      return acc;
    }, {} as Record<string, boolean>),
  );

  useEffect(() => {
    handleCheckBoxChecked(checkboxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxes, checkedItems]);

  useEffect(() => {
    updateCheckedOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasAllchecked = Object.values(checkboxes).every(option => option === true);

    setSelectAll(hasAllchecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxes, selectAll]);

  const updateCheckedOptions = () => {
    setCheckboxes(prevState => ({
      ...prevState,
      ...checkedOptions(),
    }));
  };

  onChange(checkboxes);

  const handleCheckBoxChecked = (checkbox: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filterChecked = Object.fromEntries(Object.entries(checkbox).filter(([key, value]) => value === true));
    const filterValue = Object.keys(filterChecked);
    const concatValues = filterValue.join(',');

    if (filterValue.length === 1) {
      setCheckedItems({ value: filterValue[0], label: filterValue[0] });
    } else {
      setCheckedItems({ value: concatValues, label: concatValues });
    }
  };

  const handleCheckboxChange = (value: string) => {
    setCheckboxes(prevState => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  const handleSelectAllChange = () => {
    setSelectAll(prevSelectAll => !prevSelectAll);
    const updatedCheckboxes = options.reduce((acc: any, checkbox: any) => {
      acc[checkbox.label] = !selectAll;
      return acc;
    }, {} as Record<string, boolean>);
    setCheckboxes(updatedCheckboxes);
  };

  return (
    <>
      {options && options?.length !== 0 && (
        <Container className='checkbox-group'>
          <VStack spacing={0} align='start'>
            <div id='chip-all-checks-bank-saldos'>
              <Checkbox
                isChecked={selectAll}
                onChange={handleSelectAllChange}
                className={`checkbox-item ${selectAll && 'checkbox-checked'}`}
              >
                {customText.multiSelect.allCheckbox}
              </Checkbox>
            </div>
            {options.map(checkbox => (
              <Checkbox
                id={`chip-checkbox-${checkbox.value}-bank-saldos`}
                key={checkbox.value}
                value={checkbox.label}
                isChecked={checkboxes[checkbox.label]}
                onChange={() => handleCheckboxChange(checkbox.label)}
                className={`checkbox-item ${checkboxes[checkbox.label] && 'checkbox-checked'}`}
              >
                <span dangerouslySetInnerHTML={{ __html: highlightText(checkbox.label) }} />
              </Checkbox>
            ))}
          </VStack>
        </Container>
      )}
    </>
  );
}

export default OptionCheckbox;
