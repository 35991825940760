/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint no-shadow: 2 */
/* eslint-env es6 */
/* eslint-disable no-unused-expressions */
import { Button } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent, useToastOptions } from '@react-ib-mf/style-guide-ui';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { getDownloadASCIFile, getDownloadCSVFile, getDownloadPDFFile } from '../../../services/api';
import customText from '../../lang/es.json';
import { Columns, OptionDropdown, ReportsDropdow } from './styles';

interface IBlanket {
  onClickBlanket(): void;
}

function Blanket(props: IBlanket) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions

    <div
      onClick={() => props.onClickBlanket()}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          props.onClickBlanket();
        }
      }}
      tabIndex={0}
      role='button'
      data-testid='btn-dropdown'
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 0,
        backgroundColor: 'transparent',
      }}
    />
  );
}

function ReportsComponents({ queryParams }: any) {
  const [openReports, setOpenReports] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [, setToastOptions] = useToastOptions();

  useEffect(() => {
    const handleClick = () => {
      setOpenReports(!openReports);
    };

    // Agregar el controlador de clic al elemento deseado
    if (openReports) {
      document.getElementById('header-id')?.addEventListener('click', handleClick);
    }

    // Limpia el controlador de clic cuando el componente se desmonta
    return () => {
      document.getElementById('header-id')?.removeEventListener('click', handleClick);
    };
  }, [openReports]);

  const downloadQueryInFile = async fileData => {
    setIsDownloading(true);
    const { typeFile, extensionFile, callback } = fileData;

    try {
      const FILE = await callback(queryParams);

      const blob = new Blob([FILE.data], { type: typeFile });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${customText.title}.${extensionFile}`;
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      setToastOptions({ description: customText.toast.errorDescription, status: 'error' });
    }

    pushAnalyticsEvent({
      event: 'export_query',
      content_type: 'Botones seccion Consolidado de saldos',
      item_id: extensionFile.toUpperCase(),
    });

    setIsDownloading(false);
  };

  return (
    <>
      <Columns>
        {openReports ? <Blanket onClickBlanket={() => setOpenReports(!openReports)} /> : null}
        <Button variant='primary-outline' size='md' rightIcon={<FiChevronDown />} onClick={() => setOpenReports(!openReports)}>
          {customText.reportsComponent.title}
        </Button>
        {openReports ? (
          <ReportsDropdow>
            <>
              <OptionDropdown
                data-testid='btn-csv'
                onClick={() =>
                  downloadQueryInFile({
                    callback: getDownloadCSVFile,
                    typeFile: 'text/csv; charset=utf-8',
                    extensionFile: 'csv',
                  })
                }
              >
                {customText.reportsComponent.verifyType.csv}
              </OptionDropdown>
              <OptionDropdown
                data-testid='btn-txt'
                onClick={() =>
                  downloadQueryInFile({
                    callback: getDownloadASCIFile,
                    typeFile: 'text/plain',
                    extensionFile: 'txt',
                  })
                }
              >
                {customText.reportsComponent.verifyType.txt}
              </OptionDropdown>
              <OptionDropdown
                data-testid='btn-pdf'
                onClick={() =>
                  downloadQueryInFile({
                    callback: getDownloadPDFFile,
                    typeFile: 'application/pdf',
                    extensionFile: 'pdf',
                  })
                }
              >
                {customText.reportsComponent.verifyType.pdf}
              </OptionDropdown>
            </>
          </ReportsDropdow>
        ) : null}
      </Columns>
      {isDownloading && <LoadingScreen title={customText.loadingScreen.title} description={customText.loadingScreen.description} />}
    </>
  );
}

export default ReportsComponents;
