import styled from 'styled-components';

import { FONT_TD_TABLE_COMPONENT } from '../../designTokens/fonts';

const ButtonChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 32px;
  border-radius: 8px;
  border-width: 1px;
  border-style: none;
  background: #d6e2ff;
  z-index: 1;
  margin-left: 6px;
  &.chip-open-select {
    border-color: #d6e2ff;
    background: #d6e2ff;
  }

  &.chip-selected {
    border-color: rgb(185, 208, 249);
    background: #d6e2ff;
    button {
      color: #002e68;
      &:last-child {
        width: 32px;
        &:hover {
          transition: ease-in-out 0.2s;
          background: #d6e2ff;
          border-radius: 8px;
        }
      }
    }
  }

  button {
    height: 32px;
    border: none;
    background-color: transparent;
    line-height: 0;
    font-size: 14px;
    font-family: 'Inter';
    color: #002e68;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: space-between;
  span {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    color: #131c2c;
    margin-left: 12px;
  }
  button {
    border: none;
    outline: 0;
    background: transparent;
  }
`;

const WrapOption = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 14px;
  font-family: 'Inter';
  .customRadio {
    pointer-events: none;
    margin-right: 12px;
  }
  .optionSelected {
    font-weight: bold;
    color: #2172dc;
    font-family: 'Inter';
  }
  span.option-description {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter';
  }
`;

const customToolTip = {
  borderRadius: '8px',
  color: '#F2F0F4',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  backgroundColor: '#2F3034',
};

const MenuDropdownCustomSelect = styled.div`
  background-color: 'white';
  padding: '8px 12px';
  margin-top: '4px';
  position: 'absolute';
  z-index: 2;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  width: '380px';

  .search-input {
    border-radius: 4px;
    border: 1px solid var(--Outline, #74777f);

    &::placeholder {
      color: var(--Outline, #74777f);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .btn-aplicar {
    height: 40px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  .noResults-Message {
    display: flex;
    padding: 14px 15px 0px 12px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    font-family: ${FONT_TD_TABLE_COMPONENT.fontFamily};
    font-weight: ${FONT_TD_TABLE_COMPONENT.fontWeight};
    font-size: ${FONT_TD_TABLE_COMPONENT.fontSize};
    line-height: ${FONT_TD_TABLE_COMPONENT.lineHeight};
    letter-spacing: ${FONT_TD_TABLE_COMPONENT.letterSpacing};
  }
`;

const Container = styled.div`
  max-height: 255px;
  overflow-y: auto;
  padding-bottom: 0px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #565e71;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #dae2f9;
    border-radius: 4px;
    margin: 20px;
  }

  .checkbox-item {
    height: 48px;
    font-size: 14px;
    font-family: Inter;
    padding: 0px 12px;
    width: 100%;

    .chakra-checkbox__control {
      margin-right: 12px;
      width: 20px !important;
      height: 20px !important;
    }

    .chakra-checkbox__label {
      font-size: var(--chakra-fontSizes-sm);
    }

    .chakra-checkbox__label[aria-checked='true'],
    .chakra-checkbox__label[data-checked] {
      color: var(--chakra-colors-blue-500);
      font-weight: bold;
      font-family: Inter;
    }
  }
`;

export { ButtonChip, Container, customToolTip, Label, MenuDropdownCustomSelect, WrapOption };
