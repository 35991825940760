/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
/* eslint-disable spaced-comment */
import { themeChakra } from '@wow/themes';

// INTRO: Centralizamos los estilos de cada componente en un unico archivo, para poder cambiarlos rapido
// sin tener que navegar por el componente. Usamos la tipografia del theme de wow, libreria de DS.

// SINTAXIS: 1° props css, 2° componente del componente, 3° nombre del componente como tal.
// EJEMPLO:    FONT_BTN_CUSTOM_BUTTON

// Que hacer?
// Pararse en la seccion del componente o crear una nueva EJ: //CHIP EXTRACTOS COMPONENT, luego crear una constante con la
// sintaxis definida arriba para asignarle un valor de la fuente del theme de Chackra -> http://devdesignsystem-ui.apps.dev.ib.ar/?path=/docs/design-tokens-colores--page
// Este export const nos va a permitir importar la constante en el lugar que queramos, sea jsx,tsx,ts,css.

// COMPONENTES:

// CUSTOM BUTTON COMPONENT
export const FONT_BTN_CUSTOM_BUTTON = {
  fontFamily: themeChakra.textStyles.labelLgBold.fontFamily,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLgBold.fontSize,
};

export const FONT_DESCRIPTION_SCREEN_ERROR = {
  fontFamily: themeChakra.textStyles.bodyLg.fontFamily,
  fontWeight: themeChakra.textStyles.bodyLg.fontWeight,
  fontSize: themeChakra.textStyles.bodyLg.fontSize,
};

// REPORTS COMPONENT
export const FONT_TITLE_REPORTS = {
  fontFamily: themeChakra.textStyles.labelLgBold.fontFamily,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLgBold.fontSize,
  lineHeight: themeChakra.textStyles.labelLgBold.lineHeight,
  letterSpacing: themeChakra.textStyles.labelLgBold.letterSpacing,
};

export const FONT_OPTION_DROPDOWN_REPORTS = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
  letterSpacing: themeChakra.textStyles.labelLg.letterSpacing,
};

// COLUMN CHECKBOX COMPONENT
export const FONT_TITLE_CHECKBOX = {
  fontFamily: themeChakra.textStyles.labelLgBold.fontFamily,
  fontWeightBold: themeChakra.textStyles.labelLgBold.fontWeight,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelLgBold.fontSize,
  lineHeight: themeChakra.textStyles.labelLgBold.lineHeight,
  letterSpacing: themeChakra.textStyles.labelLgBold.letterSpacing,
};

// TABLE COMPONENT
export const FONT_TABLE_COMPONENT = {
  fontFamily: themeChakra.textStyles.titleSmBold.fontFamily,
  fontWeight: themeChakra.textStyles.titleSmBold.fontWeight,
  fontSize: themeChakra.textStyles.titleSmBold.fontSize,
  lineHeight: themeChakra.textStyles.titleSmBold.lineHeight,
  letterSpacing: themeChakra.textStyles.titleSmBold.letterSpacing,
};

export const FONT_TD_TABLE_COMPONENT = {
  fontFamily: themeChakra.textStyles.bodyMd.fontFamily,
  fontWeight: themeChakra.textStyles.bodyMd.fontWeight,
  fontWeightbold: themeChakra.textStyles.bodySmSemi.fontWeight,
  fontSize: themeChakra.textStyles.bodyMd.fontSize,
  lineHeight: themeChakra.textStyles.bodyMd.lineHeight,
  letterSpacing: themeChakra.textStyles.bodyMd.letterSpacing,
};

export const FONT_TOOLTIP_TABLE_COMPONENT = {
  fontFamily: themeChakra.textStyles.bodySm.fontFamily,
  fontWeight: themeChakra.textStyles.bodySm.fontWeight,
  fontSize: themeChakra.textStyles.bodySm.fontSize,
  lineHeight: themeChakra.textStyles.bodySm.lineHeight,
  letterSpacing: themeChakra.textStyles.bodySm.letterSpacing,
};

// DETAIL SELECTED ACCOUNT COMPONENT
export const FONT_NOMBRE_BANCO_DETAIL_SELECTED_ACCOUNT = {
  fontFamily: themeChakra.textStyles.labelLgBold.fontFamily,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLgBold.fontSize,
  lineHeight: themeChakra.textStyles.labelLgBold.lineHeight,
  letterSpacing: themeChakra.textStyles.labelLgBold.letterSpacing,
};

export const FONT_DETALLE_GRUPO_DETAIL_SELECTED_ACCOUNT = {
  fontFamily: themeChakra.textStyles.labelMd.fontFamily,
  fontWeight: themeChakra.textStyles.labelMd.fontWeight,
  fontSize: themeChakra.textStyles.labelMd.fontSize,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
  letterSpacing: themeChakra.textStyles.labelMd.letterSpacing,
};

export const FONT_DETALLE_GRUPO_SPAN_DETAIL_SELECTED_ACCOUNT = {
  fontFamily: themeChakra.textStyles.titleSmBold.fontFamily,
  fontWeight: themeChakra.textStyles.titleSmBold.fontWeight,
  fontSize: themeChakra.textStyles.titleSmBold.fontSize,
  lineHeight: themeChakra.textStyles.titleSmBold.lineHeight,
  letterSpacing: themeChakra.textStyles.titleSmBold.letterSpacing,
};

// SCREEN ERROR PAGE
export const FONT_TITLE_SCREEN_ERROR = {
  fontFamily: themeChakra.textStyles.h4Bold.fontFamily,
  fontWeight: themeChakra.textStyles.h4Bold.fontWeight,
  fontSize: themeChakra.textStyles.h4Bold.fontSize,
  lineHeight: themeChakra.textStyles.h4Bold.lineHeight,
  letterSpacing: themeChakra.textStyles.h4Bold.letterSpacing,
};

export const FONT_TEXT_SCREEN_ERROR = {
  fontFamily: themeChakra.textStyles.bodyLg.fontFamily,
  fontWeight: themeChakra.textStyles.bodyLg.fontWeight,
  fontSize: themeChakra.textStyles.bodyLg.fontSize,
  lineHeight: themeChakra.textStyles.bodyLg.lineHeight,
  letterSpacing: themeChakra.textStyles.bodyLg.letterSpacing,
};

export const FONT_SPAN_TEXT_SCREEN_ERROR = {
  fontFamily: themeChakra.textStyles.titleMdBold.fontFamily,
  fontWeight: themeChakra.textStyles.titleMdBold.fontWeight,
  fontSize: themeChakra.textStyles.titleMdBold.fontSize,
  lineHeight: themeChakra.textStyles.titleMdBold.lineHeight,
  letterSpacing: themeChakra.textStyles.titleMdBold.letterSpacing,
};
