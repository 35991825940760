import { Box, Stack } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';

function SkeletonSaldos() {
  return (
    <Stack id='skeleton-saldos-container' overflow='hidden' height='calc(100vh - 290px)' data-testid='skeleton-saldos'>
      <Box>
        <Skeleton height='40px' width='178px' borderRadius='8px' marginBottom='32px' marginTop='16px' id='skeleton-saldos-exportar' />
        <Skeleton height='30px' width='200px' id='skeleton-saldos-titulo-arg' />
        <Skeleton height='80px' margin='24px 0px' borderRadius='8px' id='skeleton-saldos-total-arg' />
        <Skeleton height='80px' borderRadius='8px' id='skeleton-saldos-subtotal-arg' />

        <Skeleton height='30px' width='200px' marginTop='64px' id='skeleton-saldos-titulo-usd' />
        <Skeleton height='80px' margin='24px 0px' borderRadius='8px' id='skeleton-saldos-total-usd' />
        <Skeleton height='80px' borderRadius='8px' id='skeleton-saldos-subtotal-usd' />
      </Box>
    </Stack>
  );
}

export default SkeletonSaldos;
