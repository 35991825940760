import styled from 'styled-components';

const AccordionBankContainer = styled.div`
  .chakra-accordion__item:last-of-type {
    border-bottom-width: 0px;
  }
  .accordion-bank-item {
    color: #2f3034;
    font-size: 12px;
    font-family: Red Hat Display;
    background: white;
    border-bottom-width: 0px !important;
    border-top-width: 0px !important;

    .accordion-bank-item-header.expanded {
      border-radius: 8px 0px 0px 0px;
      border-bottom: none;

      box-shadow: none;
    }

    .accordion-bank-item-header {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px;
      height: 80px;
      align-items: center;
      border-radius: 8px;
      border-bottom: 1px #74777f solid;

      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

      .accordion-bank-item-header.expanded {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        height: 80px;
        align-items: center;
        border-radius: 8px;
        border-bottom: 1px #74777f solid;

        box-shadow: none;
      }

      .accordion-bank-item-header-brand {
        display: flex;
        .accordion-bank-item-header-brand-icon {
          display: flex;
          align-items: center;
          width: 40px;
          justify-content: center;
          margin-right: 10px;
        }

        .accordion-bank-item-header-brand2 {
          display: flex;
          flex-direction: column;

          .accordion-bank-item-header-brand2-name {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            word-wrap: break-word;
            margin-top: 14px;
          }

          .accordion-bank-item-header-brand2-date {
            color: #74777f;
            font-size: 12px;
            font-family: Inter;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0.1px;
            word-wrap: break-word;
            margin-top: -3px;
          }
        }
      }

      .accordion-bank-item-header-total {
        display: flex;
      }
      .accordion-bank-item-header-subtotal {
        display: flex;
        flex-direction: column;
        margin-right: 14px;

        .accordion-bank-item-header-subtotal-value {
          display: flex;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          white-space: nowrap;
          margin-top: 14px;

          .section-header-total-cents {
            font-size: 12px;
            font-family: Inter;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.4px;
            margin-top: 5px;
            padding-left: 2px;
          }
        }

        .accordion-bank-item-header-subtotal-label {
          color: #44474f;
          font-size: 12px;
          font-family: Inter;
          font-weight: 500;
          line-height: 15px;
          letter-spacing: 0.1px;
          word-wrap: break-word;
          text-align: right;
          padding-right: 20px;
          margin-top: -3px;
        }
      }
    }

    .accordion-bank-item-panel {
      border-bottom: none;
      padding: 0px;
    }
  }
  .accordion-bank-item.expanded {
    border: 1px solid #bec6dc !important;
    border-radius: 8px !important;
    margin-bottom: 24px;
  }
`;

export { AccordionBankContainer };
