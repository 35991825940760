/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';
import { createContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { getBanksForConsolidated } from '../../services/api';

export const getPerfilMenu = () => axios.get('home/cliente/perfil/menu');

export const AppContext = createContext({});
export default function AppContextProvider({ children }: any) {
  const [chipSaldosFetched, setChipSaldosFetched] = useState(false);
  const [errorDataSaldos, setErrorDataSaldos] = useState(false);
  const [bankNames, setBankNames] = useState([]);

  const response = useQuery(['GetBanksForConsolidated'], () =>
    getBanksForConsolidated().then(res => {
      setBankNames(
        res.data?.data?.bancos.map(banco => ({
          idBanco: banco.idBanco,
          nombreBanco: banco.nombreBanco,
        })),
      );
      return res.data;
    }),
  );

  const getChipData = () => {
    if (response.data) {
      setErrorDataSaldos(response.isError);
      setChipSaldosFetched(true);
      return response;
    } else {
      setChipSaldosFetched(false);
      if (response.isError) {
        pushAnalyticsEvent({
          event: 'trackContentGroup',
          content_group: 'Consolidado de saldos - Error de servicio',
        });
        setErrorDataSaldos(response.isError);
      }
    }
  };

  const value = useMemo(
    () => ({
      chipSaldosFetched,
      setChipSaldosFetched,
      getChipData,
      errorDataSaldos,
      setErrorDataSaldos,
      bankNames,
      setBankNames,
    }),
    [chipSaldosFetched, setChipSaldosFetched, getChipData, errorDataSaldos, setErrorDataSaldos, bankNames, setBankNames],
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
