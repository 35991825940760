import styled from 'styled-components';

const AccordionSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #2f3034;
  font-size: 20px;
  font-family: Red Hat Display;
  font-weight: 500;
  line-height: 30px;
  word-wrap: break-word;
  margin-bottom: 64px;

  .section-header-container {
    display: flex;
    color: '#2F3034';
    background: #eff0fa;
    height: 80px;
    font-weight: 700;
    word-wrap: break-word;
    justify-content: space-between;
    padding: 8px 16px;
    border-radius: 8px;
    border-bottom: 1px #74777f solid;
    margin-top: 24px;
    margin-bottom: 24px;
    .section-header-title {
      font-size: 24px;
      line-height: 32px;
      display: flex;
      align-items: center;
    }

    .section-header-total {
      font-size: 28px;
      line-height: 36px;
      display: flex;
      align-items: center;
      padding-right: 74px;

      .section-header-total-cents {
        font-size: 12px;
        font-family: Inter;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-top: -10px;
        padding-left: 2px;
      }
    }
  }
`;

export { AccordionSectionContainer };
