import styled from 'styled-components';

const AppContainerPanel = styled.div`
  padding-left: 22px;
  padding-right: 30px;
  padding-top: 20px;

  nav ol {
    margin-bottom: 12px;
  }
`;
export default AppContainerPanel;
