/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { IScrollBtn } from '../../../interfaces/IPropsCustomSelect';
import { BACKGROUND_SCROLL_BTN_CHIP_EXTRACTOS, COLOR_SCROLL_BTN_CHIP_EXTRACTOS } from '../../designTokens/colors';

const ScrollWrapper = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;

  @media (max-width: 1222px) {
    width: 500px;
  }
  @media (max-width: 1022px) {
    width: 400px;
  }
  @media (max-width: 922px) {
    width: 300px;
  }
  @media (max-width: 822px) {
    width: 200px;
  }
  @media (max-width: 722px) {
    width: 100px;
  }
`;

const ScrollContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: start;
  align-items: stretch;

  @media (max-width: 1222px) {
    width: 500px;
  }
  @media (max-width: 1022px) {
    width: 400px;
  }
  @media (max-width: 922px) {
    width: 300px;
  }
  @media (max-width: 822px) {
    width: 200px;
  }
  @media (max-width: 722px) {
    width: 100px;
  }

  button {
    padding: 6px 6px 6px 8px;
  }
`;

const ScrollButon = styled.button<IScrollBtn>`
  background: transparent;
  font-size: 2em;
  color: ${COLOR_SCROLL_BTN_CHIP_EXTRACTOS};
  cursor: pointer;
  border: 1px solid ${BACKGROUND_SCROLL_BTN_CHIP_EXTRACTOS};
  display: none;
  height: 32px;
  width: 34px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: ${props => {
    if (props.position === 'left') {
      if (props.disabled) {
        return '0px #ffffffd6';
      }
      return '12px 2px 12px #ffffffd6';
    }
    if (props.disabled) {
      return '0px #ffffffd6';
    }
    return '-12px 2px 12px #ffffffd6';
  }};
  z-index: 0;
  @media (max-width: 1222px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
`;

export { ScrollButon, ScrollContainer, ScrollWrapper };
