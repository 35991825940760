const formatParams = (value, target, newValue) => {
  return value && newValue + value.split(target).join(newValue);
};

const formatValues = (total, currency) => {
  const totalSection = { value: '', cent: '' };
  const [wholePart, decimalPart] = total.toFixed(2).toString().split('.');
  const regex = /,/gi;

  totalSection.value =
    currency === 'ARS'
      ? `$ ${parseInt(wholePart, 10).toLocaleString().replace(regex, '.')}`
      : `u$s ${parseInt(wholePart, 10).toLocaleString().replace(regex, '.')}`;
  totalSection.cent = decimalPart;

  return totalSection;
};

const formatValuesAccounts = value => {
  const regex = /,/gi;
  const splitValue = value?.toString().split('.');
  const mount = splitValue && splitValue[0] !== undefined ? parseInt(splitValue[0], 10).toLocaleString().replace(regex, '.') : '';
  const cent = splitValue && splitValue[1] ? splitValue[1].padEnd(2, '0') : '00';

  return `${mount},${cent}`;
};

const sumLastConsolidatedBalance = (banks, currency) => {
  const total = banks.reduce((subtotal, bank) => {
    const balancesByCurrencies = bank?.balancesByCurrency?.find(balance => balance.currency === currency);

    if (balancesByCurrencies) {
      const sumBalance = balancesByCurrencies.lastBalances.reduce((sum, balance) => sum + balance.lastConsolidatedBalance, 0);

      return subtotal + sumBalance;
    }

    return subtotal;
  }, 0);

  return formatValues(total, currency);
};

const getLastConsolidatedBalanceByBank = (banks, currency, bankCode) => {
  const filterByBanks = banks.filter(bank => bank.bankNumber === bankCode);
  const total = filterByBanks.reduce((subtotal, bank) => {
    const balancesByCurrencies = bank?.balancesByCurrency?.find(balance => balance.currency === currency);

    if (balancesByCurrencies) {
      const sumBalance = balancesByCurrencies.lastBalances.reduce((sum, balance) => sum + balance.lastConsolidatedBalance, 0);

      return subtotal + sumBalance;
    }

    return subtotal;
  }, 0);

  return formatValues(total, currency);
};

const formatLastUpdatedDate = (banks, currency, bankCode) => {
  const filterByBanks = banks
    .filter(bank => bank.bankNumber === bankCode)[0]
    .balancesByCurrency.filter(item => item.currency === currency)[0].lastBalances;

  const lastDateUpdated = filterByBanks.reduce((maxDate, balance) => {
    const dateBalance = new Date(balance?.lastUpdateDateTime);

    return dateBalance > maxDate ? dateBalance : maxDate;
  }, new Date(0));

  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const dateFormat = lastDateUpdated.toLocaleDateString('es-ES', options);
  const timeFormat = lastDateUpdated.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });

  return { date: dateFormat, time: timeFormat, online: filterByBanks[0]?.online };
};

const filterAccounts = (data, currency, accounts, bankCode) => {
  const filterBank = data.filter(bank => bank.bankNumber === bankCode)[0].balancesByCurrency.filter(item => item.currency === currency);

  return filterBank[0].accounts.filter(item => accounts.includes(item.account_type));
};

export {
  filterAccounts,
  formatLastUpdatedDate,
  formatParams,
  formatValuesAccounts,
  getLastConsolidatedBalanceByBank,
  sumLastConsolidatedBalance,
};
