import { Table, Tbody, Td, Thead, Tr } from '@chakra-ui/table';
import React from 'react';

import customText from '../../../../lang/es.json';
import { formatValuesAccounts } from '../../../../utils/accordion';

function AccordionTable({ accountData, currencySelected }) {
  const renderAccountTable = () => {
    const currencyTable = currencySelected === 'ARS' ? '$' : 'u$s';
    return accountData?.map((account, index) => {
      return (
        <Table variant='simple' key={index} data-testid='table'>
          <Thead borderTop='1px solid #2F3034'>
            <Tr
              backgroundColor='#EFF0FA'
              fontSize='14px'
              fontWeight='400'
              fontFamily='Inter'
              lineHeight='16px'
              letterSpacing='0.10px'
              color='#5E5E61'
            >
              <Td colSpan='6' padding='0 0 0 66px' height='32px'>
                <span
                  style={{ color: '#2F3034', paddingRight: '8px', fontWeight: '700' }}
                >{`${account?.account_type} ${account?.account_number}`}</span>{' '}
                Denominación <span style={{ color: '#2F3034' }}>{account?.account_name ?? '-'}</span>
              </Td>
            </Tr>
            <Tr fontSize='12px' fontWeight='400' fontFamily='Inter' lineHeight='16px' letterSpacing='0.40px' color='#565E71'>
              <Td padding='8px 16px 2px' border='none' textAlign='end' width='200px'>
                {customText.accordion.table.columnContable}
              </Td>
              <Td border='none' padding='8px 16px 0px' textAlign='end' width='200px'>
                {customText.accordion.table.columnInicio}
              </Td>
              <Td border='none' padding='8px 16px 0px' textAlign='end' width='200px'>
                {customText.accordion.table.columnActual}
              </Td>
              <Td border='none' padding='8px 16px 0px' textAlign='end' width='200px'>
                {customText.accordion.table.column24Horas}
              </Td>
              <Td border='none' padding='8px 16px 0px' textAlign='end' width='200px'>
                {customText.accordion.table.column48Horas}
              </Td>
              <Td border='none' padding='8px 20px 0px' />
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td
                padding='0px 16px 8px'
                fontSize='14px'
                fontWeight='400'
                fontFamily='Inter'
                lineHeight='20px'
                letterSpacing='0.25px'
                textTransform='lowercase'
                textAlign='end'
                width='200px'
              >
                {account?.balances?.countable_balance !== undefined
                  ? `${currencyTable} ${formatValuesAccounts(account?.balances?.countable_balance)}`
                  : '-'}
              </Td>
              <Td
                padding='0px 16px 8px'
                fontSize='14px'
                fontWeight='400'
                fontFamily='Inter'
                lineHeight='20px'
                letterSpacing='0.25px'
                textTransform='lowercase'
                textAlign='end'
                width='200px'
              >
                {account?.balances?.initial_operating_balance !== undefined
                  ? `${currencyTable} ${formatValuesAccounts(account?.balances?.initial_operating_balance)}`
                  : '-'}
              </Td>
              <Td
                padding='0px 16px 8px'
                fontSize='14px'
                fontWeight='400'
                fontFamily='Inter'
                lineHeight='20px'
                letterSpacing='0.25px'
                textTransform='lowercase'
                textAlign='end'
                width='200px'
              >
                {` ${
                  account?.balances?.current_operating_balance !== undefined
                    ? `${currencyTable} ${formatValuesAccounts(account?.balances?.current_operating_balance)}`
                    : '-'
                }`}
              </Td>
              <Td
                padding='0px 16px 8px'
                fontSize='14px'
                fontWeight='400'
                fontFamily='Inter'
                lineHeight='20px'
                letterSpacing='0.25px'
                textTransform='lowercase'
                textAlign='end'
                width='200px'
              >
                {` ${
                  account?.balances?.projected_balance_24hs !== undefined
                    ? `${currencyTable} ${formatValuesAccounts(account?.balances?.projected_balance_24hs)}`
                    : '-'
                }`}
              </Td>
              <Td
                padding='0px 16px 8px'
                fontSize='14px'
                fontWeight='400'
                fontFamily='Inter'
                lineHeight='20px'
                letterSpacing='0.25px'
                textTransform='lowercase'
                textAlign='end'
                width='200px'
              >
                {` ${
                  account?.balances?.projected_balance_48hs !== undefined
                    ? `${currencyTable} ${formatValuesAccounts(account?.balances?.projected_balance_48hs)}`
                    : '-'
                }`}
              </Td>
              <Td border='none' padding='8px 20px 0px' width='100px' />
            </Tr>
          </Tbody>
        </Table>
      );
    });
  };

  return renderAccountTable();
}

export default AccordionTable;
