/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, Input, InputGroup, InputLeftElement, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiChevronDown, FiSearch, FiX } from 'react-icons/fi';

import customText from '../../lang/es.json';
import { Dropdown } from './Dropdown';
import OptionCheckbox from './OptionCheckbox';
import { ButtonChip, customToolTip, Label } from './Styled';

export default function MultiSelect({
  options,
  onSelectOption,
  onClearFilter,
  value,
  label,
  ellipsis,
  tooltipText,
  name,
  onCheckedOptions,
  defaulValue,
  chipSelectedDefault,
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  const [stateButton, setStateButton] = useState('');
  const [isDisabledButtonReset, setIsDisabledButtonReset] = useState(true);
  const [isDisabledButtonSave, setIsDisabledButtonSave] = useState(true);
  const [OptionsChecked, setOptionsChecked] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [noResultsMessage, setNoResultsMessage] = useState('');

  const setEllipsis = () => {
    let newLabel = value?.customLabel ? value?.description : value?.label;
    if (newLabel !== undefined && ellipsis !== undefined && ellipsis > 0) {
      newLabel = newLabel?.length > ellipsis ? newLabel?.slice(0, ellipsis).concat('...') : newLabel;
      newLabel = value?.customLabel ? `${value?.label} | ${newLabel}` : newLabel;
    }
    return newLabel;
  };

  useEffect(() => {
    setIsDisabledButtonSave(transformChipSelected(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, OptionsChecked]);

  const concatValues = (values: any) => {
    const filterKeys = [];
    let filterKey;
    const checkedValues = { value: '', label: '' };

    for (const key in values) {
      if (values[key] === true) {
        filterKeys.push(key);
      }
    }

    const filterValues = concatBankValues(filterKeys)
      ?.map(bank => {
        return bank.value;
      })
      .join(',');

    if (filterKeys.length > 1) {
      filterKey = `${filterKeys[0]} +${filterKeys.length - 1}`;
    } else {
      filterKey = filterKeys[0];
    }

    checkedValues.value = filterValues;
    checkedValues.label = filterKey;

    return checkedValues;
  };
  const concatBankValues = (values: any) => {
    return options.filter(bank => values?.some(filter => bank.label.includes(filter)));
  };

  const compareValues = (valueDefault, valueCurrent) => {
    const bankValueDefault = Object.values(valueDefault)?.join();
    const bankValueCurrent = Object.values(valueCurrent)?.join();

    return bankValueDefault === bankValueCurrent;
  };

  const transformChipSelected = bankData => {
    let newDefaultValues = {};
    const splitBanks = chipSelectedDefault?.codigoBanco?.split(',');

    const findBankNames = bankData
      .filter(banks => {
        return splitBanks.includes(banks.value);
      })
      .map(bank => {
        return bank.label;
      });

    newDefaultValues = bankData.reduce((acc: any, checkbox: any) => {
      acc[checkbox.label] = findBankNames.includes(checkbox.label);
      return acc;
    }, {});

    const checkedOptKeys = Object.keys(OptionsChecked);
    const chipDefaultKeys = Object.keys(newDefaultValues);
    const checkedOptValues = Object.values(OptionsChecked).join('');
    const chipDefaultValues = Object.values(newDefaultValues).join('');

    if (checkedOptKeys.length !== chipDefaultKeys.length) {
      return false;
    }

    if (checkedOptValues !== chipDefaultValues) {
      return false;
    }

    return true;
  };

  const handleClickSetValues = (selectValues: any) => {
    onSelectOption(concatValues(selectValues));
    setIsOpen(false);
  };

  const onChangeOptions = checkedValues => {
    const updatedOptionsChecked = { ...OptionsChecked, ...checkedValues };

    if (JSON.stringify(OptionsChecked) !== JSON.stringify(updatedOptionsChecked)) {
      setOptionsChecked(updatedOptionsChecked);
    }

    if (defaulValue && updatedOptionsChecked) {
      setIsDisabledButtonReset(compareValues(defaulValue, updatedOptionsChecked));
    }

    if (options.filter(option => option.label.toLowerCase().includes(searchInput.toLowerCase())).length === 0) {
      setNoResultsMessage('No hay resultados para la búsqueda');
    } else {
      setNoResultsMessage('');
    }
  };

  const handleResetButton = () => {
    resetValues();
    onClearFilter();
    const allChecksOption = document.getElementById('chip-all-checks-bank-saldos');

    const selectCheck = allChecksOption.querySelector('.chakra-checkbox__control');
    const selectLabel = allChecksOption.querySelector('.chakra-checkbox__label');
    const selectContainer = allChecksOption.querySelector('.chakra-checkbox');

    if (selectCheck) {
      selectCheck.removeAttribute('data-checked');
    }
    if (selectLabel) {
      selectLabel.removeAttribute('data-checked');
    }
    if (selectContainer) {
      selectContainer.removeAttribute('data-checked');
    }
  };

  const resetValues = () => {
    const banks = Object.keys(OptionsChecked);
    const updatedOptionsChecked = { ...OptionsChecked };

    if (banks.length > 0) {
      OptionsChecked[banks[0]] = true;

      for (let i = 1; i < banks.length; i++) {
        OptionsChecked[banks[i]] = false;
      }
    }

    setOptionsChecked(updatedOptionsChecked);
  };

  const saveCheckedOptions = () => {
    const values = onCheckedOptions?.value.split(',');

    return options
      .filter(bank => values?.some(filter => bank.value.includes(filter)))
      .reduce((acc: any, checkbox: any) => {
        acc[checkbox.label] = true;
        return acc;
      }, {} as Record<string, boolean>);
  };

  const noOptionsChecked = values => {
    const checkedValues = Object.values(values).includes(true);
    return !checkedValues;
  };

  const highlightSearchText = text => {
    const searchRegex = new RegExp(searchInput, 'gi');
    return text.replace(searchRegex, match => `<strong>${match}</strong>`);
  };

  useEffect(() => {
    if (!value?.default && value !== undefined) {
      setStateButton('chip-selected');
    } else if (isOpen && value?.default) {
      setStateButton('chip-open-select');
    } else {
      setStateButton('');
    }
  }, [isOpen, value]);

  useEffect(() => {
    const handleClick = () => {
      setIsOpen(!isOpen);
    };

    // Agregar el controlador de clic al elemento deseado
    if (isOpen) {
      document.getElementById('header-id')?.addEventListener('click', handleClick);
    }

    // Limpia el controlador de clic cuando el componente se desmonta
    return () => {
      document.getElementById('header-id')?.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  return (
    <div id='chip-bank-saldos' data-testid='chip-bank-saldos'>
      <Tooltip data-testid='tooltip' hasArrow label={tooltipText} aria-label='A tooltip' placement='top' sx={customToolTip}>
        <span>
          <Dropdown
            data-testid='dropdown'
            name={name || ''}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            target={
              <ButtonChip id={name} className={stateButton}>
                <button data-testid='btn-chip' onClick={() => setIsOpen(prev => !prev)}>
                  {value ? `${setEllipsis()}` : 'Selecciona'}
                </button>
                {value?.default ? (
                  <button data-testid='btn-chevrondown' onClick={() => setIsOpen(prev => !prev)}>
                    <FiChevronDown fontSize={18} />
                  </button>
                ) : (
                  <button onClick={() => setIsOpen(prev => !prev)}>
                    <FiChevronDown fontSize={18} />
                  </button>
                )}
              </ButtonChip>
            }
          >
            <Label>
              <span id='chip-title-bank-saldos'>{label}</span>
              <button id='chip-close-bank-saldos' data-testid='btn-fix' onClick={() => setIsOpen(false)}>
                <FiX fontSize={20} />
              </button>
            </Label>
            {options.length >= 5 && (
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <FiSearch fontSize={20} />
                </InputLeftElement>
                <Input
                  data-testid='search-input'
                  className='search-input'
                  placeholder='Buscar'
                  value={searchInput}
                  onChange={e => setSearchInput(e.target.value)}
                />
              </InputGroup>
            )}
            <OptionCheckbox
              options={options.filter(option => option.label.toLowerCase().includes(searchInput.toLowerCase()))}
              onChange={onChangeOptions}
              checkedOptions={saveCheckedOptions}
              highlightText={highlightSearchText}
            />
            {noResultsMessage && <p className='noResults-Message'>{noResultsMessage}</p>}
            <div style={{ height: '1px', background: '#C7C6CA', margin: '23.5px 0px' }} />
            <div style={{ height: '48px', display: 'flex', justifyContent: 'space-between' }}>
              <Button size='md' onClick={handleResetButton} isDisabled={isDisabledButtonReset} variant='primary-text'>
                {customText.multiSelect.resetButton}
              </Button>
              <Button
                className='btn-aplicar'
                id='chip-button-apply-bank-saldo'
                isDisabled={isDisabledButtonSave || noOptionsChecked(OptionsChecked)}
                size='md'
                data-testid='btn-aplicar'
                onClick={() => {
                  handleClickSetValues(OptionsChecked);
                  setSearchInput('');
                }}
              >
                {customText.multiSelect.applyButton}
              </Button>
            </div>
          </Dropdown>
        </span>
      </Tooltip>
    </div>
  );
}
