/* eslint-disable no-restricted-globals */
import { Button } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';

import { IScreenError } from '../../../interfaces/IScreenError';
import messages from '../../lang/es.json';
import { Container } from './styled';

function ScreenError(props: IScreenError) {
  const { title, description, image } = props;
  function reloadPage() {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Error de servicio',
      item_id: 'CTA - Reintentar',
    });
    location.reload();
  }
  return (
    <Container>
      <div className='image'>
        <img data-testid='screen-Err-img' src={image} alt={messages.screenErrorText.alt} />
      </div>
      <div className='title'>
        <p data-testid='screen-Err-title'>{title}</p>
      </div>
      <div className='description' data-testid='screen-Err-desc'>
        {description}
      </div>
      <div style={{ marginTop: '32px' }}>
        <Button size='lg' onClick={() => reloadPage()} variant='primary-text'>
          {messages.screenErrorText.textButton}
        </Button>
      </div>
    </Container>
  );
}

export default ScreenError;
