/* eslint-disable no-param-reassign */
import { Banco, IOption, TipoCuenta, TipoMoneda } from '../../interfaces/IPropsCustomSelect';

type typesFilters = Banco[] | TipoCuenta[] | TipoMoneda[];

const createObjectFilter = (data: typesFilters, value: string, label: string) => {
  return data.map(
    item =>
      ({
        value: Object.entries(item).filter(x => x.includes(value))[0][1] as string,
        label: Object.entries(item).filter(x => x.includes(label))[0][1] as string,
      } as IOption),
  );
};

const clearFilterBank = data => {
  data.forEach((option, index) => {
    option.default = index === 0;
  });
};

const formatTypeAccountLabel = data => {
  return data?.map(bank => ({
    ...bank,
    tipoCuentas: bank.tipoCuentas.map(account => {
      const modifiedAccount = { ...account };

      if (account?.codigoTipoCuenta === 'CA') {
        modifiedAccount.nombreTipoCuenta = 'Caja de ahorro';
      }
      if (account?.codigoTipoCuenta === 'CC') {
        modifiedAccount.nombreTipoCuenta = 'Cuenta corriente';
      }

      if (account?.codigoTipoCuenta === 'CA+CC') {
        modifiedAccount.nombreTipoCuenta = 'Cuenta corriente y caja de ahorro';
      }

      return modifiedAccount;
    }),
  }));
};

const getDefaultBank = filtersData => {
  return (filtersData?.data?.bancos || []).reduce((result, bank, index) => {
    return { ...result, [bank.nombreBanco]: index === 0 };
  }, {});
};

const getTypeAccountsForSelectedBanks = (selectedBankList, dataBanks) => {
  const defaultTypeAccount = [
    { value: 'CC', label: 'Cuenta corriente', isDisabled: true, default: false },
    { value: 'CA', label: 'Caja de ahorro', isDisabled: true, default: false },
    { value: 'CA+CC', label: 'Cuenta corriente y caja de ahorro', isDisabled: true, default: false },
  ];

  const splitBanks = selectedBankList?.value.split(',');
  const filterByBank = dataBanks?.filter(bank => splitBanks?.includes(bank?.idBanco));
  const filterByTypeAccount = Array.from(
    new Set(
      filterByBank?.reduce((result, bank) => {
        bank?.tipoCuentas?.forEach(tipoCuenta => {
          result.push(tipoCuenta.codigoTipoCuenta);
        });
        return result;
      }, []),
    ),
  );

  if (filterByTypeAccount?.length > 1) {
    return defaultTypeAccount?.map(opcion => ({
      ...opcion,
      isDisabled: false,
    }));
  }

  return defaultTypeAccount.map(opcion => ({
    ...opcion,
    isDisabled: !filterByTypeAccount.includes(opcion.value),
  }));
};

const filterMoneda = (codigoTipoCuenta, data) => {
  const filteredTipoCuentas = data.flatMap(banco => banco.tipoCuentas.filter(tc => tc.codigoTipoCuenta === codigoTipoCuenta));
  const allMonedas = filteredTipoCuentas.flatMap(tc => tc.tipoMonedas.map(tm => tm.moneda));

  return Array.from(new Set(allMonedas));
};

const getCurrencyForSelectedBanks = (selectedTypeAccount, dataBanks) => {
  const defaultCurrency = [
    { value: 'ARS', label: 'ARS', isDisabled: true, default: false },
    { value: 'USD', label: 'USD', isDisabled: true, default: false },
    { value: 'ARS y USD', label: 'ARS y USD', isDisabled: true, default: false },
  ];

  let uniqueMonedas = [];
  if (selectedTypeAccount?.value === 'CA' || selectedTypeAccount?.value === 'CC') {
    uniqueMonedas = filterMoneda(selectedTypeAccount?.value, dataBanks);
  }

  if (selectedTypeAccount?.value === 'CA+CC') {
    uniqueMonedas = Array.from(new Set(filterMoneda('CA', dataBanks).concat(filterMoneda('CC', dataBanks))));
  }

  if (uniqueMonedas.length === 0) {
    return defaultCurrency;
  }
  if (uniqueMonedas.length === 1) {
    return defaultCurrency.map(opcion => ({
      ...opcion,
      isDisabled: !uniqueMonedas.includes(opcion.value),
    }));
  }
  return defaultCurrency.map(opcion => ({
    ...opcion,
    isDisabled: false,
  }));
};

export {
  clearFilterBank,
  createObjectFilter,
  formatTypeAccountLabel,
  getCurrencyForSelectedBanks,
  getDefaultBank,
  getTypeAccountsForSelectedBanks,
};
