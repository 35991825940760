/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode } from 'react';
import { FiSearch } from 'react-icons/fi';

import { MenuDropdownCustomSelect } from './Styled';

function MenuChildren(props: React.JSX.IntrinsicElements['div']) {
  return <div {...props} />;
}

function Blanket(props: React.JSX.IntrinsicElements['div']) {
  return (
    <div
      data-testid='btn-dropdown'
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
      }}
      {...props}
    />
  );
}

const aux = (name: string) => {
  const element = document.getElementById(name);
  const rect = element?.getBoundingClientRect();
  return rect?.left;
};

function Dropdown({
  children,
  isOpen,
  target,
  onClose,
  name,
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
  readonly name: string;
}) {
  return (
    <div>
      {target}
      {isOpen ? (
        <MenuDropdownCustomSelect
          id='chip-bank-container-saldos'
          style={{
            backgroundColor: 'white',
            padding: '8px 12px',
            marginTop: '4px',
            position: 'absolute',
            zIndex: 2,
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
            width: '380px',
            left: aux(name),
          }}
        >
          <MenuChildren>{children} </MenuChildren>
        </MenuDropdownCustomSelect>
      ) : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );
}

function DropdownIndicator() {
  return <FiSearch fontSize={20} />;
}

export { Dropdown, DropdownIndicator };
